import React from 'react'
import whatsapp from '../assets/images/whatsapp.png'

const WhatsappIcon = () => {
    return (
        <div className='icon-container'>
            <a href="https://wa.me/5515991118173" target='_blank'>
                <img src={whatsapp} alt="whatsapp icon" />
            </a>
        </div>
    )
}

export default WhatsappIcon