import React from 'react'
import { AnimatePresence } from 'framer-motion'
import { Route, Routes, useLocation } from 'react-router-dom'
import ProductDetail from './components/ProductDetail'
import Home from './components/Home'

const AnimatedRoutes = () => {
    const location = useLocation()
    return (
        <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>
                <Route path='/' element={<Home />} />
                <Route path='/produto/:productUrl' element={<ProductDetail />} />
            </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRoutes