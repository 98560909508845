import React, { useEffect, useRef } from 'react'
import ProductGallery from './ProductGallery'
import duplaAnchora from '../assets/images/dupla-ancora-policarbonato.jpg'
import triplaTrava from '../assets/images/primas-tripla-trava.jpg'
import cableSeal from '../assets/images/primax-cable-seal.jpg'
import mb40 from '../assets/images/primax-mb-40cm.jpg'
import cadeado from '../assets/images/primax-cadeado-com-arame.jpg'
import multilock from '../assets/images/multilock.png'
import { Link } from 'react-router-dom';
import Transition from './Transition'
import LOGO from '../assets/images/logo.png'
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

const Home = () => {

    const isMountedRef = useRef(false);
    useEffect(()=>{
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
    },[])

    return (
        <main className='mh-100 container-xxl d-flex justify-content-center'>

            <div className="col-12">
            <ProductGallery />
            <h2 className='poppins fs-1 mt-5 underline'>Nossos produtos</h2>
            <div className="card-container d-flex gap-4">
<div className="product-card pb-4">
                    <Link to="/produto/multilock" className="image-card-container w-100 rounded-4">
                        <img className='w-100 h-100 rounded-4 object-fit-cover' src={multilock} alt="multilock" />
                    </Link>
                    <div className="d-flex flex-column justify-content-between flex-grow-1">
                        <h2 className='montserrat fs-4 py-3 pb-0 mb-0 px-2 text-center'>MULTILOCK25</h2>
                        <Link className='pt-3' to="/produto/multilock">IR À PÁGINA DO PRODUTO</Link>
                    </div>
                </div>
                <div className="product-card pb-4">
                    <Link to="/produto/primax-mb-40" className="image-card-container w-100 rounded-4">
                        <img className='w-100 h-100 rounded-4 object-fit-cover' src={mb40} alt="mb40" />
                    </Link>
                    <div className="d-flex flex-column justify-content-between flex-grow-1">
                        <h2 className='montserrat fs-4 py-3 pb-0 mb-0 px-2 text-center'>PRIMAX MB-40</h2>
                        <Link className='pt-3' to="/produto/primax-mb-40">IR À PÁGINA DO PRODUTO</Link>
                    </div>
                </div>
                <div className="product-card pb-4">
                    <Link to="/produto/cable-seal" className="image-card-container w-100 rounded-4">
                        <img className='w-100 h-100 rounded-4 object-fit-cover' src={cableSeal} alt="cableSeal" />
                    </Link>
                    <div className="d-flex flex-column justify-content-between flex-grow-1">
                        <h2 className='montserrat fs-4 py-3 pb-0 mb-0 px-2 text-center'>PRIMAX Cable Seal</h2>
                        <Link className='pt-3' to="/produto/cable-seal">IR À PÁGINA DO PRODUTO</Link>
                    </div>
                </div>
                <div className="product-card pb-4">
                    <Link to="/produto/dupla-ancora" className="image-card-container w-100 rounded-4">
                        <img className='w-100 h-100 rounded-4 object-fit-cover' src={duplaAnchora} alt="duplaAnchora" />
                    </Link>
                    <div className="d-flex flex-column justify-content-between flex-grow-1">
                        <h2 className='montserrat fs-4 py-3 pb-0 mb-0 px-2 text-center'>Dupla Ãncora policarbonato</h2>
                        <Link className='pt-3' to="/produto/dupla-ancora">IR À PÁGINA DO PRODUTO</Link>
                    </div>
                </div>
                <div className="product-card pb-4">
                    <Link to="/produto/tripla-trava" className="image-card-container w-100 rounded-4">
                        <img className='w-100 h-100 rounded-4 object-fit-cover' src={triplaTrava} alt="triplaTrava" />
                    </Link>
                    <div className="d-flex flex-column justify-content-between flex-grow-1">
                        <h2 className='montserrat fs-4 py-3 pb-0 mb-0 px-2 text-center'>PRIMAX Tripla trava</h2>
                        <Link className='pt-3' to="/produto/tripla-trava">IR À PÁGINA DO PRODUTO</Link>
                    </div>
                </div>
                <div className="product-card pb-4">
                    <Link to="/" className="image-card-container w-100 rounded-4">
                        <img className='w-100 h-100 rounded-4 object-fit-cover' src={cadeado} alt="cadeado" />
                    </Link>
                    <div className="d-flex flex-column justify-content-between flex-grow-1">
                        <h2 className='montserrat fs-4 py-3 pb-0 mb-0 px-2 text-center'>Primax, cadeado com arame, dupla âncora</h2>
                        <Link className='pt-3' to="/produto/missing">IR À PÁGINA DO PRODUTO</Link>
                    </div>
                </div>
            </div>
            </div>

        </main>
    )
}

export default Transition(Home)