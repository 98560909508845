import React from 'react';
import { motion } from 'framer-motion';
import Logo from '../assets/images/logo.png'

const Transition = (OriginalComponent) => {
    const TransicionComponent = () => {
    return (
        <>
            <OriginalComponent />
            <motion.div 
                className='slide-in'
                initial={{scaleY:0}}
                animate={{scaleY:0}}
                exit={{scaleY:1}}
                transition={{ duration:1.2, ease: [0.22, 1, 0.36, 1]}}
            >
                <img src={Logo} alt='logo' />
            </motion.div>
            <motion.div
                className='slide-out'
                initial={{ scaleY: 1 }}
                animate={{ scaleY: 0 }}
                exit={{ scaleY: 0 }}
                transition={{ duration: 1.2, ease: [0.22, 1, 0.36, 1] }}
            >
                <img src={Logo} alt='logo' />
            </motion.div>
        </>
    );
};

    return TransicionComponent;
};

export default Transition;