import './assets/styles.css';
import { BrowserRouter } from 'react-router-dom';
import Navbar from './components/Navbar';
import AnimatedRoutes from './AnimatedRoutes';
import Footer from './components/Footer';
import WhatsappIcon from './components/WhatsappIcon';

function App() {
    return (
        <BrowserRouter>
            <Navbar />
            <AnimatedRoutes />
            <Footer />
            <WhatsappIcon />
        </BrowserRouter>
    );
}

export default App;
