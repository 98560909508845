import React from 'react'

const Footer = () => {
    return (
        <div className='container-fluid footer'>
            <div className="container-xxl d-flex flex-column align-items-center gap-1 py-4">
                <p>© 2023 - Primax - Todos los derechos reservados</p>
                <p>comercial@primmax.com.br Av. Paulo Antunes Moreira 1815, Distrito Industrial, Iperó SP.</p>
            </div>
        </div>
    )
}

export default Footer