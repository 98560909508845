import React from 'react'
import galleryImage1 from '../assets/images/home-gallery-1.jpg'
import galleryImage2 from '../assets/images/home-gallery-2.jpg'
import galleryImage3 from '../assets/images/home-gallery-3.jpg'

const ProductGallery = () => {
    return (
        <div id="carouselExample" className="carousel slide carousel-fade navbar-margin" data-bs-ride="carousel">
            <div className="carousel-inner rounded-4">
                <div className="carousel-item active position-relative">
                    <img src={galleryImage1} className="d-block w-100 h-100 object-fit-cover" alt="..." />
                    
                    {/* Versión escritorio */}
                    <div className="carousel-caption d-none d-md-flex text-center" style={{ 
                        position: 'absolute', 
                        top: '0', 
                        left: '0', 
                        width: '100%', 
                        height: '30%', 
                        color: 'white', 
                        background: 'rgba(0, 0, 0, 0.5)', 
                        padding: '20px', 
                        flexDirection: 'column', 
                        justifyContent: 'center' 
                    }}>
                        <h1 style={{ 
                            fontSize: '5rem', 
                            fontWeight: '800', 
                            color: 'orange', 
                            borderBottom: '4px solid orange', 
                            paddingBottom: '5px' 
                        }}>
                            Multilock 25
                        </h1>
                        <h3 style={{ fontSize: '2.5rem' }}>
                            dupla trava, o lacre mais seguro e confiável do mercado.
                        </h3>
                    </div>

                    {/* Versión móvil */}
                    <div className="carousel-caption d-flex d-md-none text-center" style={{ 
                        position: 'absolute', 
                        top: '0', 
                        left: '0', 
                        width: '100%', 
                        height: '30%', 
                        color: 'white', 
                        background: 'rgba(0, 0, 0, 0.5)', 
                        padding: '20px', 
                        flexDirection: 'column', 
                        justifyContent: 'center' 
                    }}>
                        <h1 style={{ 
                            fontSize: '2.5rem', 
                            fontWeight: '800', 
                            color: 'orange', 
                            borderBottom: '4px solid orange', 
                            paddingBottom: '5px' 
                        }}>
                            Multilock 25
                        </h1>
                    </div>
                </div>
                
                <div className="carousel-item">
                    <img src={galleryImage2} className="d-block w-100 h-100 object-fit-cover" alt="..." />
                </div>
                <div className="carousel-item">
                    <img src={galleryImage3} className="d-block w-100 h-100 object-fit-cover" alt="..." />
                </div>
            </div>
            
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    )
}

export default ProductGallery


